@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&family=Roboto+Mono:wght@300;500&display=swap");
@import "~video-react/styles/scss/video-react";
@import "~react-photo-view/dist/react-photo-view.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  outline: none !important;
}

::-webkit-scrollbar {
  width: 6px;
  background: #c7c7c7;
  border-radius: 10px;

  @media (max-width: 767px) {
    display: none;
  }
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  // border: 2px solid #fff;
}

::-webkit-scrollbar-thumb {
  background: #283642;
  border-radius: 6px;
}

body {
  background: #1d252c;
  background-size: cover;
  font-size: 1rem;
  font-weight: 400;
  color: #ececec;
  line-height: 1.8;
  min-height: 100vh;

  @media (max-height: 700px) {
    font-size: 14px;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

a {
  color: #7cd959;
  text-decoration: none;

  &:hover {
    color: #7cd959;
    text-decoration: underline;
  }
}

.global_radiogroup {
  .Mui-checked.MuiRadio-root {
    span > svg + svg {
      color: #7cd959;
    }
  }
  @media (max-width: 1400px) {
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }
}

.global_inputfield {
  margin: 15px 0;
  position: relative;

  @media (max-width: 991px) {
    margin: 10px 0;
  }

  .search_icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.5;
  }

  input {
    min-width: 250px;
    width: 100%;
    border: 1px solid #cecece;
    padding: 8px 13px;
    color: #fff;
    background: transparent;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    -webkit-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;

    @media (max-width: 991px) or (max-height: 700px) {
      font-size: 14px;
    }

    &.placeholder_light::placeholder {
      color: rgba($color: #fff, $alpha: 0.8);
    }

    &[type="search"] {
      padding-left: 45px !important;
    }

    &.white_bg_input {
      background-color: rgba($color: #283642, $alpha: 1);
      border-radius: 10px;
      border-color: transparent;
      padding: 11px 13px;

      &::placeholder {
        color: rgba($color: #fff, $alpha: 0.6);
      }

      &.green_border {
        border: 1px solid rgba(124, 217, 89, 0.6);
      }
    }

    &:focus {
      border-color: #7cd959;
    }
  }
}

.w-300 {
  max-width: 300px;
  width: 100%;

  @media (max-width: 1199px) {
    max-width: 250px;
  }
}

.w-250 {
  max-width: 250px;
  width: 100%;
}

.htmlselect {
  &.conservative {
    margin: 15px 0;
    select {
      background: rgba(255, 255, 255, 0.15);
      width: 100%;
      border: 1px solid transparent;
      padding: 10px 12px;
      border-radius: 4px;
      color: #fff;

      &:focus {
        border-color: #8ec23a8e;
      }

      option {
        color: #000;
      }
    }
  }
}

.global_select {
  background: #404040;
  border-radius: 10px;
  color: #fff;
  font-weight: 300;

  .MuiInputBase-root {
    border-radius: 8px;
    background-color: rgba($color: #283642, $alpha: 0.8);

    .MuiSvgIcon-root {
      color: #fff;
    }

    &.Mui-focused,
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #7cd959 !important;
      }
    }
  }

  .MuiSelect-select {
    color: #fff;

    @media (max-width: 1400px) or (max-height: 700px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      &.MuiSelect-outlined {
        padding: 13.5px 14px;
      }
    }
  }

  .MuiSvgIcon-root {
    color: #fff;
  }

  label.MuiInputLabel-root {
    color: #fff;

    @media (max-width: 767px) {
      font-size: 12px;
      top: 2px;
    }
  }

  @media (max-width: 767px) {
    fieldset {
      font-size: 12px !important;
    }
  }

  label.MuiInputLabel-root.Mui-focused {
    color: #7cd959;
  }
}

.MuiPaper-root.MuiMenu-paper {
  background: #1e252b !important;
  border-radius: 8px;
  color: #fff;

  .MuiMenuItem-root.Mui-selected {
    background-color: rgba(160, 248, 128, 0.2);
  }
}

@keyframes scaling {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.global_button {
  background-color: #7cd959;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  border: 2px solid #7cd959;
  border-radius: 7px;
  padding: 9px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  outline: none !important;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;

  @media (max-width: 991px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }

  &.bold {
    font-weight: 700;
  }

  &.inline_anchor-button {
    background-color: transparent;
    border: 2px solid #7cd959;
    color: #7cd959;

    &:hover {
      background: #7cd959;
      color: #000;
    }
    &.bg-red {
      border-color: rgb(197 28 28);
      color: #fff;
      background: transparent;

      &:hover {
        background-color: rgba(197, 28, 28, 0.4);
      }
    }
  }

  &.inline_anchor {
    background-color: transparent;
    border: none;
    color: #7cd959;
    padding: 0 !important;
    display: inline-block;
    width: auto;

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  &.inline_anchor-bordered {
    background-color: transparent;
    border: 2px solid #7cd959;
    color: #7cd959;
    display: inline-block;
    width: auto;

    &:hover {
      background: #7cd959;
      color: #000;
    }

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  &.noAnimationInvisible {
    opacity: 0 !important;
  }

  &.visibleWithAnimation {
    opacity: 1 !important;
    animation: scaling 1s ease-in-out;
  }

  &:hover {
    background: transparent;
    color: #7cd959;
  }

  &.bg-red {
    border-color: rgb(197 28 28);
    background: rgb(197 28 28);
    color: #fff;

    &:hover {
      background-color: rgba(194, 36, 36, 0.6);
      border-color: rgba(194, 36, 36, 0.6);
    }
  }
}

.authentication_main {
  .dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header_app_logo {
    position: absolute;
    top: 35px;
    left: 40px;
    cursor: pointer;

    @media (max-width: 767px) {
      max-width: 200px;
    }
  }
  .form_wrapper {
    max-width: 300px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    @media (max-width: 767px) {
      max-width: 235px;
    }

    a.login-with-twitch {
      text-decoration: none;
      background: #6519d7;
      border: 2px solid #6519d7;
      border-radius: 7px;
      padding: 10px 15px;
      outline: none !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      width: 100%;
      margin-top: 15px;
      cursor: pointer;
      -webkit-transition: 0.2s all ease-in-out;
      transition: 0.2s all ease-in-out;

      @media (max-width: 767px) {
        padding: 10px;
      }

      &:hover {
        background: transparent;
      }
      &.no-clickable {
        pointer-events: none;
      }
    }

    .forgotpswd-successlink {
      background-color: #7cd959;
      padding: 10px 10px;
      border-radius: 0px;
      color: #000;
      margin-top: 30px;
      display: flex;
      align-items: flex-start;
      text-align: left;
    }
  }
  .auth_title {
    font-size: 30px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
}

// .MuiBackdrop-root {
//   &.MuiModal-backdrop {
//     background-color: rgba(0, 0, 0, 0.68);
//   }
// }

.MuiDialog-container {
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .modal_title_close {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #fff;
    font-size: 12px;
    outline: none !important;
    svg {
      font-size: 18px;
    }
  }
  .MuiDialog-paper {
    background: #1d252c; //changed the background color for Call to action task
    color: #fff;
    @media (max-width: 767px) {
      margin: 15px;
    }
  }
  .MuiDialogContent-root {
    padding-bottom: 40px;

    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.login-with-twitch {
  .MuiPaper-root {
    width: 100%;
  }
  &.copy_link-popup {
    .MuiPaper-root {
      max-width: 800px;

      .MuiDialogContent-root {
        @media (max-width: 899px) {
          padding-bottom: 160px !important;
        }
      }

      .copylink_popup-actions {
        @media (max-width: 899px) {
          margin-left: 0;
          padding: 0 15px 15px !important;

          & > div {
            padding-left: 0;
          }
        }
      }
    }
  }
  .MuiDialogTitle-root {
    text-align: center;
    padding-top: 35px;
    padding-bottom: 15px;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    & > img {
      margin-right: 10px;
    }
  }
  input {
    background: rgba($color: #fff, $alpha: 0.15);
    border: 1px solid transparent;
    border-radius: 4px;

    &:focus {
      border-color: #8ec23a82;
    }
  }
  .twitchLoginPopupBtn {
    width: 100%;
    background: #9146ff;
    border: none;
    outline: none;
    color: #fff;
    padding: 8px 15px;
    border-radius: 7px;
    cursor: pointer;
    margin-top: 20px;
    -webkit-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;

    &:hover {
      background: #6519d7;
    }
  }
  .twitchsignup {
    color: #a365ff;
    display: block;
    font-weight: 700;
    text-align: center;
  }
}

.app_header-main {
  padding: 15px 30px;
  border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.12);

  &.menuOpen + div.app-sidebar-main {
    left: 0;
  }

  @media (max-width: 1199px) {
    padding: 20px 25px;

    .goto-profile {
      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 15px 65px 15px 20px;
    position: relative;
    z-index: 101;

    .stream_indicator {
      & > p {
        display: none;
      }
      & > div {
        height: 20px !important;
        margin-right: 15px !important;
      }
      svg {
        font-size: 20px !important;
      }
    }

    .hamburger_menu {
      background-color: transparent;
      border: none;
      outline: none;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      span {
        width: 20px;
        height: 2px;
        background: #858585;
        display: block;
        margin: 3px 0;
        -webkit-transition: 0.2s all ease-in-out;
        transition: 0.2s all ease-in-out;
      }
      &.close {
        span:first-child {
          transform: rotate(45deg);
          margin-top: 8px;
        }
        span:last-child {
          transform: rotate(-45deg);
          margin-top: -10px;
        }
        span:nth-child(2) {
          transform: translateX(15px);
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .goto-profile {
      img {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }

  .main_app-logo {
    position: relative;

    span {
      position: absolute;
      letter-spacing: 2px;
      font-size: 10px;
      text-transform: uppercase;
      color: #7cd959;
      top: 2px;
      left: calc(100% + 5px);
      @media (max-width: 767px) {
        font-size: 8px;
        margin-left: 4px;
        top: 2px;
      }
      @media (max-width: 580px) {
        font-size: 8px;
        margin-left: 0px;
        top: -4px;
        right: 0;
      }
    }
  }

  .premium_user {
    position: relative;
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      background: url("./assets/images/crown.png") no-repeat center;
      background-color: #fff;
      box-shadow: 0px 0px 3px rgba($color: #000000, $alpha: 0.8);
      background-size: 18px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      right: -5px;
      bottom: -5px;
      z-index: 99;
      border: 4px solid #1a1d1f;

      @media (max-width: 1199px) {
        width: 25px;
        height: 25px;
        background-size: 14px;
      }
    }
  }

  .app-logo-after {
    width: 280px;
    @media (max-width: 1360px) {
      max-width: 400px;
    }
    @media (max-width: 1199px) {
      max-width: 330px;
    }
    @media (max-width: 767px) {
      max-width: 200px;
    }
    @media (max-width: 580px) {
      max-width: 180px;
    }
  }
  .goto-profile {
    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.app-sidebar-main {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #171d21;
  box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.35);
  overflow: auto;

  .goto-profile {
    margin: 25px auto !important;
    display: block !important;
    text-align: center;
    border-bottom: 1px solid #363636;

    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: center center;
    }
  }

  .main_app-logo {
    position: relative;
    width: 100%;
    padding: 30px 20px;
    border-bottom: 1px solid #7cd959;
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.5);

    img {
      width: 100%;
    }

    span {
      position: absolute;
      letter-spacing: 2px;
      font-size: 10px;
      text-transform: uppercase;
      color: #7cd959;
      top: 2px;
      left: calc(100% + 5px);
      @media (max-width: 767px) {
        font-size: 8px;
        margin-left: 4px;
        top: 2px;
      }
      @media (max-width: 580px) {
        font-size: 8px;
        margin-left: 0px;
        top: -4px;
        right: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  // @media (max-width: 991px) {
  //   width: 200px;
  // }
  @media (max-width: 767px) {
    width: 100%;
    z-index: 99;
    background-color: #1d252c;
    padding-top: 70px;
    left: -100%;
    height: 100%;
    -webkit-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }

  .sidebar-menu {
    margin-bottom: 0;
    list-style: none;

    .sub-menu {
      padding-left: 15px;
    }

    li {
      list-style: none;

      &::marker {
        display: none;
      }
    }

    @media (max-width: 767px) {
      &.upper-menu {
        border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
      }
    }

    a {
      display: flex;
      align-items: center;
      margin: 35px 0;
      padding-left: 40px;
      color: rgba($color: #fff, $alpha: 0.3);
      font-weight: 300;
      letter-spacing: 0.5px;
      font-family: "Poppins", sans-serif;
      text-decoration: none;

      .logout_loader {
        svg {
          margin-right: 0 !important;
        }
      }

      @media (max-width: 991px) {
        padding-left: 28px;
      }

      @media (max-width: 767px) {
        padding: 0 20px;
        max-width: 200px;
        margin: 35px auto;
      }

      &:hover {
        color: rgba($color: #fff, $alpha: 0.7);

        svg {
          path,
          polygon {
            fill: rgba($color: #fff, $alpha: 0.7);
          }
        }
      }

      &.active {
        color: #7cd959;

        svg {
          path,
          polygon {
            fill: #7cd959;
          }
        }
      }

      svg {
        margin-right: 14px;

        * {
          opacity: 1;
        }
        path,
        polygon {
          fill: rgba($color: #fff, $alpha: 0.3);
        }
      }
      &.logout-btn {
        &:hover {
          path,
          polygon {
            stroke: rgba($color: #fff, $alpha: 0.7);
          }
        }
        path {
          stroke: rgba($color: #fff, $alpha: 0.3);

          &:last-child {
            fill: none;
          }
          &:first-child {
            fill: none;
          }
        }
      }
    }
  }
}

.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  .global_button {
    text-decoration: none;
    font-weight: 700;
  }
}

.main-app {
  .app-sidebar-main,
  .main-app-wrapper {
    -webkit-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }
  .sidebar-menu {
    a {
      svg {
        min-width: 25px;
      }
    }
    .menu-name {
      white-space: nowrap;
    }
  }
  .lower-menu {
    p {
      opacity: 0;
      visibility: hidden;
    }
    span {
      margin: 10px 0;
    }
  }
  &.ready {
    .lower-menu {
      p {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.sidebar-collapsed {
    .app-sidebar-main {
      width: 80px;

      .lower-menu {
        p {
          display: none;
        }
        // span {
        //   margin: 10px 0;
        // }
      }
      .sidebar-menu {
        .sub-menu {
          padding-left: 0;
        }
        a {
          padding-left: 28px;
          .menu-name {
            display: none;
          }
        }
      }
    }
    .main-app-wrapper {
      max-width: calc(100% - 80px);
    }
  }
}

.main-app-wrapper {
  max-width: calc(100% - 280px);
  width: 100%;
  position: fixed;
  right: 0;
  height: 100%;
  bottom: 0;

  @media (max-width: 767px) {
    height: calc(100% - 70px);
    max-width: 100%;
  }

  .main-app-insider {
    overflow: auto;
    border-radius: 10px;
    width: calc(100% - 40px);
    height: calc(100% - 65px);
    margin-left: 20px;
    margin-top: 20px;
    overflow: auto;
    padding-right: 20px;
    padding-left: 20px;

    @media (max-width: 991px) {
      margin-left: 10px;
      margin-top: 20px;
      width: calc(100% - 25px);
      height: calc(100% - 65px);
    }
    @media (max-width: 767px) {
      padding: 0 20px;
      margin-left: 0px;
      width: 100%;
      height: calc(100% - 60px);
    }
    @media (max-width: 480px) {
      padding: 0 15px;
    }
  }

  .common-title {
    font-size: 24px;
    font-weight: 500;
    color: #ececec;
    margin-bottom: 14px;

    @media (max-width: 1400px) {
      font-size: 20px;
    }
    @media (max-width: 991px) {
      font-size: 18px;
    }
  }
  .card-overlay-box {
    background: #171d21;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.45);

    @media (max-width: 991px) {
      &.p-4 {
        padding: 18px !important;
      }
    }

    &:last-child {
      margin-bottom: 10px;
    }

    .play_overlays {
      margin-top: -5px;
      margin-bottom: -5px;
      position: relative;

      .add-more-anm-face {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        @media (max-width: 1600px) {
          p {
            font-size: 14px;
          }
        }
      }

      @media (max-width: 767px) {
        max-height: 300px;
        overflow: auto;

        button {
          padding: 15px 15px 15px 62px !important;

          img {
            max-width: 46px !important;
          }
        }

        .random_btn {
          .open_random-alert_setting {
            left: 170px;
          }
          button {
            padding: 5px 15px 5px 5px !important;

            img {
              margin-right: 12px !important;
            }
          }
        }
      }

      .row {
        margin: 0 -10px;

        @media (max-width: 767px) {
          margin: 0;
        }

        & > div {
          margin: 5px 0;
          padding: 0 10px;
          @media (max-width: 1367px) {
            max-width: 100%;
            flex: 0 0 100%;
          }
          @media (max-width: 767px) {
            padding: 0;
          }
        }
      }

      button {
        border: none;
        background: #283642;
        border-radius: 8px;
        padding: 13px 15px 13px 15px;
        color: #fff;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;
        cursor: pointer;
        -webkit-transition: 0.2s all ease-in-out;
        transition: 0.2s all ease-in-out;
        position: relative;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & > * {
          position: relative;
          z-index: 2;
        }

        @media (max-width: 991px) {
          padding: 11px 15px 11px 15px;
        }

        &.custom_alert-button {
          padding: 11px 13px 11px 13px;
          border: 2px solid rgba(141, 194, 58, 44%) !important;
        }

        &.external_alert-button {
          padding: 11px 13px 11px 13px;
          border: 2px solid #d57ff1 !important;
        }

        &:hover {
          background: #334350;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;

          &:hover {
            background: #4d4d4d;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.marketplace-main {
  .MuiTabs-root {
    background: linear-gradient(
        to right,
        #171d21,
        rgba($color: #171d21, $alpha: 0.34)
      )
      no-repeat;
    box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.45);
    border-radius: 10px 10px 0 0;
  }
  @media (max-width: 1199px) {
    .marketplace_header {
      flex-wrap: wrap;
      .actions {
        width: 100%;
        margin-top: 10px;
        .cta_btn {
          margin: 0;
          margin-right: 20px;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .actions {
      margin-top: 20px !important;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .marketplace_header {
      .actions {
        padding-bottom: 80px;
        position: relative;
        margin-bottom: 0;
        .MuiButtonBase-root {
          &.MuiIconButton-root {
            margin-right: 0 !important;
            margin-left: 10px !important;
          }
        }
        .global_inputfield {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.MuiTabs-root {
  border-bottom: 1px solid #4d4d4d;
  padding: 0 20px;
  position: relative;

  @media (max-width: 991px) {
    &::after {
      content: "";
      position: absolute;
      width: 70px;
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(to left, rgb(31, 42, 49), transparent)
        no-repeat;
      border-top-right-radius: 10px;
    }

    & > .MuiTabs-scroller {
      overflow: auto !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  button.MuiButtonBase-root {
    padding: 15px 10px 15px;
    margin-right: 30px;
    color: #535557;
    font-size: 16px;
    min-width: auto;

    @media (max-width: 1400px) {
      margin-right: 12px;
      font-size: 14px;
      padding: 16px 8px 15px;
    }

    @media (max-width: 767px) {
      margin-right: 10px;
      font-size: 12px;
      padding: 15px 8px 15px;
    }

    &:last-child {
      margin-right: 0;
    }

    &.Mui-selected {
      color: #fff;
    }
  }
  .MuiTabs-indicator {
    background-color: #7cd959;
  }
  &.sectioned {
    // border-top: 1px solid #4d4d4d !important;
    background: #171d21;
    border-radius: 8px 8px 0 0;
  }
}

.MuiSwitch-root {
  padding: 5px !important;
  .MuiSwitch-track {
    background: #92999f;
    border-radius: 15px !important;
  }
  .MuiSwitch-thumb {
    background: #fff !important;
  }
  .Mui-checked + .MuiSwitch-track {
    background: #7cd959 !important;
  }
}

.mp-overlay_one {
  background: #171d21;
  padding: 16px;
  text-align: center;
  border-radius: 10px;
  position: relative;
  // -webkit-transition: 0.2s all ease-in-out;
  // transition: 0.2s all ease-in-out;

  &.custom_ov {
    background: rgba($color: #fff, $alpha: 0.07);
  }

  // @media (max-width: 767px) {
  //   overflow: hidden !important;
  // }

  .overlay_namee {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.added {
    background: rgba(142, 194, 58, 0.22);
  }

  img.overlay_img {
    width: 100%;
    object-fit: cover;
    border-radius: 6px;

    &.custom {
      object-fit: contain;
      background: #1b1b1e;

      &.hasPreview {
        object-fit: cover;
      }
    }
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 40px);
  }

  &.face {
    p {
      max-width: 100%;
    }
  }

  .play_overlay {
    border: none;
    background: none;
    color: #fff;
    padding: 0;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      font-size: 40px;
      vertical-align: middle;
    }
  }

  .add_overlay {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 2;
    border: 1px solid #fff;
    outline: none;
    cursor: pointer;
    background: #2b2b2b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 2px;
    -webkit-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
    width: 30px;
    height: 30px;

    &.added {
      border-color: #7cd959;
      color: #7cd959;
    }

    // &.delete {
    //   right: 30px;
    // }

    svg {
      font-size: 22px;
    }
  }
  .remove-icon,
  .add_overlay {
    display: none;
  }
  &.added {
    .add_overlay {
      display: block;
    }
  }
  &:hover {
    .add_overlay {
      display: block;
    }
    .added-icon {
      display: none;
    }
    .remove-icon {
      display: block;
    }
  }
}

.my-profile_main {
  .card-overlay-box {
    position: relative;

    .edit-profile_btn {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 999;
      border: 2px solid #7cd959;

      &.cancel {
        right: 70px;
      }
    }
  }

  .my-profile_userimg_wrapper {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
    padding-bottom: 30px;
    text-align: center;
  }
  .profile_user-img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

.logout_circular_ {
  position: absolute;
  bottom: 42px;
  right: 30px;
}

.output_res_div {
  position: relative;
  display: flex;
  height: 100%;
  .video_turnonoff {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    background: transparent;
    background: #7cd959;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: inset 0px 0px 3px 1px rgba($color: #000000, $alpha: 0.4);
    -webkit-transition: 0.15s all ease-in-out;
    transition: 0.15s all ease-in-out;

    @media (max-width: 1199px) {
      left: 10px;
      right: auto;
    }

    &:hover {
      box-shadow: inset 0px 0px 5px 1px rgba($color: #000000, $alpha: 0.6);
    }

    svg {
      font-size: 22px;
    }
  }
  .no_video_available {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: 0.8;
    background: #000000;
    border-radius: 10px;
    min-height: 350px;
    max-height: 350px;
    max-width: 500px;
    margin-left: auto;

    @media (max-width: 1199px) {
      margin-left: 0;
    }

    @media (max-width: 1600px) {
      min-height: 280px;
      max-height: 280px;
    }
  }
}

.main_source_canvs_dash {
  position: relative;
  max-width: 500px;
  min-height: 280px;
  width: 100%;
  margin-left: auto;
  overflow: hidden;
  background: #000000;
  border-radius: 10px;

  @media (max-width: 1199px) {
    margin-left: 0;
  }

  .wrapper_canvas.dashboard {
    .circular_loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) !important;
    }

    .overlay_image {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      object-fit: cover;
      border-radius: 10px;
    }
    .backgroundcanvas {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      transform: scale(-1, 1);
      border-radius: 10px;
    }
    .maskselfiecanvas {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      transform: scale(-1, 1);
      border-radius: 10px;
    }
    .sourcevideo {
      width: 100%;
      transform: scale(-1, 1);
      z-index: -1;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .demoCanvas {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 99;
      transform: scale(-1, 1);
      border-radius: 10px;
    }
  }
}

.main_source_canvs {
  position: relative;
  // max-width: 500px;
  // min-height: 350px;
  // width: 100%;
  margin-left: auto;
  overflow: hidden;
  // background: #000000;
  // border-radius: 10px;

  @media (max-width: 1199px) {
    margin-left: 0;
  }

  .circular_loader {
    // position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }

  .overlay_image {
    // position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    object-fit: cover;
    // border-radius: 10px;
  }
  .backgroundcanvas {
    // position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    // transform: scale(-1, 1);
    // border-radius: 10px;
  }
  .maskselfiecanvas {
    // position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    // transform: scale(-1, 1);
    // border-radius: 10px;
  }
  .sourcevideo {
    width: 100%;
    // transform: scale(-1, 1);
    z-index: -1;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
  }
  .demoCanvas {
    // position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    // transform: scale(-1, 1);
    // border-radius: 10px;
  }
}

@keyframes circle-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  .runCircle {
    animation: circle-rotation 1s linear infinite;
  }
}

.upload_drag_drop {
  border: 2px dashed #b0b0b0;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  position: relative;
  margin-top: 20px;

  & > p {
    margin-bottom: 0;
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: pointer;
    opacity: 0;
  }
  .clearUpload {
    color: #fff;
    top: 20px;
    right: 20px;
    position: absolute;
    border: 2px solid #7cd959;
    background: #7cd959;
    border-radius: 50%;
    padding: 1px;

    &:hover {
      svg {
        color: #7cd959;
      }
    }

    svg {
      font-size: 15px;
      color: #000;
    }
  }
}

//CTA(Call_to_action)
.cta_btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 1rem;
  padding: 0 16px;
}

.cta-popup {
  margin: 12px;
  border: 2px solid #7cd959;
  border-radius: 8px;
  font-family: "Red Hat Display", sans-serif !important;
  .cta-title {
    color: #7cd959;
    font-weight: 600;
    font-size: 28px;
    margin-top: -24px;
    margin-bottom: 12px;
  }
  .cta-close {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .centered-gift {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    .gift {
      position: relative;
      display: inline-block;
    }

    .text-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #7cd959;
      line-height: 1;
    }

    .text-overlay p {
      margin: 0;
      font-weight: 600;
    }

    .three {
      font-size: 100px;
      transform: rotate(20deg);
    }
    .small-text {
      font-size: 11px;
      font-weight: 700 !important;
      margin-top: 0;
      margin-bottom: 5px;
      line-height: 1;
    }
    .free-text {
      font-size: 30px;
      margin-top: 0;
      line-height: 0.8;
    }
  }
  .step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Red Hat Display", sans-serif !important;
    .stepper-icon {
      height: 55px;
      width: 55px;
    }
    .stepper_label {
      color: #7cd959;
      font-size: 14px;
      font-weight: 600;
      line-height: 0.8px;
    }
  }
}
//Custom Alert
.custom-alert-popup,
.help {
  .custom-alert-dialogTitle {
    text-align: "left";
    padding: 10px;
    margin-left: 30px;
    .title-main {
      display: flex;
      // justify-content: center;
      align-items: center;
      .popup-title {
        margin-top: 20px;
        padding: 0;
        font-size: 30px;
      }
    }
  }

  .custom-alert-dialogContent {
    padding: 10px 20px;
    margin-top: 5px;

    .custom-alert-border {
      border: 5px solid #7cd959;
      border-radius: 8px;
    }
  }

  .bring-stream {
    text-align: center;
    margin-top: 30px;
  }
  .hyperlink-main {
    display: flex;
    justify-content: right;
    margin-top: 40px;
    padding: 20px;
  }

  .custom-alert-bold {
    color: #7cd959;
  }

  .custom-alert-button {
    background-color: #7cd959;
    border: 2px solid #7cd959;
    border-radius: 24px;
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 700;
    color: #ffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    -webkit-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;

    &:hover {
      background: transparent;
      color: #7cd959;
    }

    .btn-hover {
      position: relative;
    }

    &:hover .btn-image-hover {
      opacity: 1;
      visibility: visible;
    }

    .btn-image-hover {
      position: absolute;
      top: -10px;
      left: calc(100% + 10px);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.1s ease-in-out;
      z-index: 1;
    }
  }

  .refer-btn {
    padding: 8px 24px !important;
    font-size: 14px;
    font-weight: 700;
  }

  .image-button {
    position: relative;
    top: 16px;
    left: 10px;
    margin-left: 200px;
    cursor: pointer;
  }

  .info-txt {
    position: absolute;
    color: white !important;
    font-size: 10px;
    bottom: 220px;
  }

  .folder-exist {
    bottom: 80px;
  }

  .meeting {
    bottom: 60px;
  }

  .file-name {
    color: #7cd959 !important;
    bottom: 50px;
    li {
      list-style: none;
      display: inline;
      padding: 8px;
      font-size: 12px;
    }
  }
  .button-container {
    margin-left: 85%;
  }

  .popup-image {
    position: fixed;
    bottom: 0;
    right: 0;
    .popup-image__img {
      transform: scaleX(-1);
      &:after {
        content: "";
        display: block;
        width: 50%;
        height: 100%;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
        );
        transform: scaleX(-1);
        opacity: 0.4;
      }
    }
  }
}
.obs-info {
  position: relative;
  @media (max-width: 767px) {
    max-width: 320px !important;
  }
  .obs-title {
    font-size: 16px;
    font-weight: 600;
    color: #ffff;
  }
  .obs-border {
    border: 2px solid #7cd959;
    border-radius: 8px;
  }
  .clear-text {
    font-size: 14px;
    margin-top: 2px;
  }
  .blurred-text {
    margin-top: 2px;
    text-shadow: 0 0 6px white;
    font-size: 14px;
    color: transparent;
  }
  .alert-wrapper {
    position: relative;
    margin-bottom: 10px;
  }
  .custom-alert {
    position: absolute;
    font-size: 10px;
    top: -24px;
    right: 0px;
  }
}

.copy_btn {
  padding: 4px 8px;
  color: #000;
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  background: #7cd959;
  border: 2px solid #7cd959;
  text-transform: uppercase;
  border-radius: 8px;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;

  &:hover {
    background: transparent;
    color: #fff;
  }
}

.obs_started_wraning-dialog {
  .MuiDialog-container {
    .MuiDialog-paper {
      max-width: 600px;
      min-width: 550px;
    }
  }
}

.payment-main {
  .free_trial {
    span {
      color: #7cd959;
    }
    button {
      background-color: #7cd959;
      font-weight: 700;
      font-style: italic;
      border: none;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .payment_plans-wrapper {
    display: flex;
    // overflow: auto;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.plan_one {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  max-width: calc(50% - 15px);
  min-width: 400px;
  width: 100%;
  text-align: center;
  border: 1px solid #1a1d1f;
  transition: 0.15s all ease-in-out;
  -webkit-transition: 0.15s all ease-in-out;

  @media (max-width: 767px) {
    min-width: auto;
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &:nth-child(n + 3) {
    margin-top: 30px;
  }

  &.inside-final_payment-popup {
    max-width: 100%;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  &:hover {
    box-shadow: 4px 4px 10px rgba($color: #000000, $alpha: 0.8);
    transform: scale(1.02);
  }

  .plan-name {
    font-size: 24px;
    padding: 26px 20px;
    border-radius: 10px 10px 0 0;
    background-color: #7cd959;
    color: #000;
    font-weight: 700;
    @media (max-width: 767px) {
      font-size: 20px;
      padding: 22px 15px;
    }
  }
  .price-per-month {
    padding: 24px 20px;
    font-size: 45px;
    margin-bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    small {
      font-size: 20px;
      vertical-align: super;
      margin-right: 5px;
    }
    span {
      color: #7cd959;
      font-size: 20px;
    }
    @media (max-width: 767px) {
      font-size: 35px;
      padding: 20px 15px;
    }
  }
}

.benefits {
  border-top: 1px solid #7cd959;
  display: flex;
  justify-content: center;

  ul {
    max-width: 330px;
    width: 100%;
    text-align: left;
    list-style: none;
    margin-bottom: 0;
    padding: 15px 0;

    @media (max-width: 767px) {
      max-width: calc(100% - 30px);
      font-size: 14px;
    }

    li {
      padding: 12px 12px 12px 32px;
      position: relative;

      @media (max-width: 767px) {
        padding: 8px 12px 8px 32px;
        font-size: 12px;
      }

      span {
        color: #7cd959;
        font-weight: 600;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background: url("../src/assets/images/arrow.png") no-repeat center left;
        background-size: 22px;

        @media (max-width: 767px) {
          width: 20px;
          height: 20px;
          background-size: 16px;
          top: 8px;
          transform: none;
        }
      }
    }
  }

  & + div {
    button {
      font-weight: 700;

      &:hover {
        transform: scale(0.96);
      }
    }
  }
}

.overlay-individual {
  .input_main {
    position: relative;

    .actions {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      height: 25px;
    }
  }
}

.dashboard-main {
  position: relative;

  .threed_overlays-main {
    // display: flex;
    // justify-content: space-between;

    .default_buttons {
      // width: calc(100% - 150px);
    }
    .random_btn {
      position: relative;

      .open_random-alert_setting {
        position: absolute;
        left: 200px;
        top: 50%;
        z-index: 10;
        cursor: pointer;
        opacity: 0.7;
        transform: translateY(-50%);
        height: 20px;

        @media (max-width: 1400px) {
          left: 185px;
        }

        &:hover {
          opacity: 1;
        }
      }

      button {
        // height: 119px;

        &:hover {
          background: rgba(141, 194, 58, 52%) !important;
        }
      }

      .random_image {
        width: 58px;
        opacity: 0.7;
      }
    }
  }

  .free_trial-warning {
    span {
      color: #7cd959;
      font-weight: 700;
    }
    position: absolute;
    right: 2px;
    top: 2px;
    @media (max-width: 767px) {
      position: unset;
      padding-bottom: 18px;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
    }
  }
  @media (max-width: 991px) {
    .main-stream-control_dahsboard {
      flex-direction: column-reverse;

      .obs-info {
        .obs-title {
          font-size: 14px !important;
        }
      }

      & > div:last-child {
        text-align: left;

        .global_button {
          margin-left: 0 !important;
        }
        .switch {
          text-align: left !important;
          margin-bottom: 15px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .streaming-alerts_all {
      .tab-panel_ {
        &.p-4 {
          padding: 18px !important;
        }
      }
    }
    .overlay-individual {
      .overlays_select {
        display: block !important;
        margin-left: 0 !important;
        margin-top: 15px !important;

        & > span {
          display: block;
          margin-bottom: 25px;

          & + div {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

.login-with-twitch {
  &.tryfreepremium {
    .MuiDialog-container {
      .MuiDialog-paper {
        max-width: 700px;

        .MuiDialogContent-root {
          h4 {
            line-height: 1.65;
          }
        }
      }
    }
  }
}

.payment_stepperr-final {
  .MuiDialog-container {
    .MuiDialog-paper {
      max-width: 1000px;

      .MuiDialogContent-root {
        padding-bottom: 80px;

        .stepper_actionbar-bottom {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          padding: 25px;
          background-color: #252b2f;
          z-index: 12;
        }
      }

      .MuiStepper-root {
        .MuiStepLabel-iconContainer {
          svg {
            width: 40px;
            height: 40px;
            color: transparent;
            border: 2px solid #7cd959;
            border-radius: 50%;
            padding: 3px;

            @media (max-width: 767px) {
              width: 30px;
              height: 30px;
            }

            text {
              font-weight: 700;
              fill: #7cd959;
            }

            &.MuiStepIcon-root.Mui-completed {
              color: #7cd959;
            }
          }
        }
        .MuiStepLabel-labelContainer {
          color: #fff;

          .Mui-active {
            color: #7cd959;
          }
          .Mui-completed {
            color: #fff;
          }

          @media (max-width: 767px) {
            .MuiStepLabel-label {
              font-size: 12px;
            }
          }
        }
        .MuiStepConnector-root {
          top: 20px;
          left: calc(-50% + 28px);
          right: calc(50% + 28px);

          @media (max-width: 767px) {
            top: 14px;
          }
        }
        .Mui-active {
          svg {
            color: #7cd959;

            text {
              fill: #000;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

// Sample container
.payment_success-container {
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #1a1d1f;
  border-radius: 15px;
  position: relative;

  .ribbon-2 {
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 0px; /* the top offset */
    opacity: 0;
    position: absolute;
    inset: var(--t) calc(-1 * var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - var(--f)),
      calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),
      0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f) / 2)
    );
    background: #b50945;
    box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;

    &.showing {
      --t: 25px;
      opacity: 1;
    }
  }

  // Main CSS
  .check-container {
    width: 200px;
    height: 225px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;

    .check-background {
      width: 100%;
      height: calc(100% - 1.25rem);
      background: linear-gradient(to bottom right, #7cd959, #7cd959);
      box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
        0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
      transform: scale(0.84);
      border-radius: 50%;
      animation: animateContainer 0.75s ease-out forwards 0.75s;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      svg {
        width: 65%;
        transform: translateY(0.25rem);
        stroke-dasharray: 80;
        stroke-dashoffset: 80;
        animation: animateCheck 0.35s forwards 1.25s ease-out;
      }
    }

    .check-shadow {
      bottom: calc(-15% - 5px);
      left: 0;
      border-radius: 50%;
      background: radial-gradient(closest-side, #7cd959, transparent);
      animation: animateShadow 0.75s ease-out forwards 0.75s;
    }
  }
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}

@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}
.dashboard_bottom-wrapper {
  .overlays_all-listitems {
    max-width: 400px;
    width: 100%;
    @media (max-width: 1400px) {
      max-width: 350px;
    }
    @media (max-width: 1200px) {
      max-width: 340px;
    }
  }
  .streaming-alerts_all {
    width: calc(100% - 430px);
    margin-left: auto;

    @media (max-width: 1400px) {
      width: calc(100% - 380px);
    }
    @media (max-width: 1200px) {
      width: calc(100% - 370px);
    }
  }
}

.payment_method-popup {
  &.login-with-twitch {
    .MuiPaper-root {
      max-width: 900px;
    }
    &.one_card {
      .MuiPaper-root {
        max-width: 450px;
      }
    }
  }
}

.login-with-twitch {
  &.add_payment_method {
    .MuiPaper-root {
      max-width: 800px;
    }
  }
}

.add_card-main-wrapper {
  position: relative;

  .flipper_button {
    position: absolute;
    z-index: 11;
    right: 175px;
    top: 10px;
  }

  .card_small-method {
    &.front {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 420px;
      width: 100%;
      z-index: 9;

      &.flipped {
        transform: translateX(-50%) rotateY(180deg);
        opacity: 0;
        z-index: 0;
      }
    }
    &.back {
      position: relative;
      z-index: 0;
      opacity: 0;
      max-width: 420px;
      width: 100%;
      margin: 0 auto 30px;
      transform: rotateY(-180deg);

      &.flipped {
        transform: rotateY(0deg);
        opacity: 1;
        z-index: 9;
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

.card_small-method {
  position: relative;
  background: rgba($color: #fff, $alpha: 0.1);
  border-radius: 10px;
  padding: 45px 25px 25px;
  height: 230px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 4px rgba($color: #000000, $alpha: 0.6);
  cursor: pointer;
  -webkit-transition: 0.3s box-shadow ease-in-out, 0.3s transform ease-in-out,
    0.3s opacity ease-in-out;
  transition: 0.3s box-shadow ease-in-out, 0.3s transform ease-in-out,
    0.3s opacity ease-in-out;

  &.new_added {
    .card_details {
      .card-number {
        max-width: calc(100% - 150px);

        input {
          min-width: auto;
        }
      }
      .card-expiry {
        max-width: 145px;
        margin-left: auto;
        display: flex;
        align-items: center;

        input {
          min-width: auto;
          text-align: center;

          &.expiryMonth {
            min-width: 58px;
            max-width: 58px;
          }
          &.expiryYear {
            min-width: 67px;
            max-width: 67px;
          }
        }
      }
    }
  }

  &:hover {
    box-shadow: 4px 4px 8px rgba($color: #000000, $alpha: 0.5);
  }

  &.default {
    border: 1px solid #8ec23ab1;
  }

  .card-chip {
    position: absolute;
  }

  .default_card {
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 3px 10px;
    background-color: #7cd959;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    border-radius: 10px 0 10px 0;
  }

  .card_details {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px;
    padding: 25px;

    .card-number,
    .card-expiry,
    .card-name {
      font-family: "Roboto Mono", monospace !important;
      font-weight: 500;
      font-size: 20px;
      text-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.8);
    }
    .card-name {
      font-size: 16px;
      font-weight: 300;
    }

    p {
      margin-bottom: 0;

      &.card-name {
        margin-top: 10px;
      }
    }
  }

  .card-type {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .detach_card {
    position: absolute;
    bottom: 5px;
    right: 10px;
    z-index: 11;
  }
  @media (max-width: 767px) {
    height: 180px;

    .default_card {
      font-size: 10px;
    }
    .card-type {
      width: 40px;
    }
    .card-chip {
      width: 35px;
    }
    .card_details {
      bottom: 5px;
      padding: 15px 25px;
      .card-number,
      .card-expiry {
        font-size: 12px;
      }
      .card-name {
        font-size: 12px;
      }
      .detach_card svg {
        font-size: 18px !important;
      }
    }
  }
}

.inliner_paywith-existing {
  position: relative;
  @media (max-width: 767px) {
    text-align: center !important;
  }

  button {
    font-weight: 700;
    &:hover {
      opacity: 0.8 !important;
    }
    @media (max-width: 767px) {
      &.global_button.inline_anchor {
        margin: 10px auto !important;
        font-size: 12px !important;
        background-color: #252b2f !important;
        padding: 0 10px !important;
        z-index: 9;
        position: relative;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 17px;
    width: calc(100% - 345px);
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    background: #7cd959;

    @media (max-width: 1400px) {
      width: calc(100% - 314px);
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  &::before {
    content: "";
    position: absolute;
    right: 17px;
    width: 38px;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    background: #7cd959;
    @media (max-width: 767px) {
      width: calc(100% - 30px);
      right: 15px;
      left: 15px;
      z-index: 1;
    }
  }
}
.MuiListItemIcon-root {
  color: #ffffff !important;
}
.MuiListItemText-root p {
  color: #ffffff !important;
}

.stepper_label_tutorial {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.got_an-update {
  color: rgb(255, 255, 255);
  background: rgb(141, 194, 58);
  border-radius: 50%;
  /* padding: 2px 5px; */
  margin-left: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
}

.alert_time-hourly {
  // font-family: "Roboto Mono", monospace !important;
  font-size: 18px !important;

  @media (max-width: 1400px) {
    font-size: 15px !important;
  }
}

.settings_main {
  @media (max-width: 767px) {
    .card-overlay-box:first-of-type {
      .row {
        .col-9 {
          max-width: 100%;
          flex: 0 0 100%;
          margin: 0 !important;
          margin-bottom: 30px !important;

          .global_inputfield {
            margin: 0 !important;
          }
          .upload_drag_drop {
            margin-top: 0 !important;

            img {
              width: 100%;
            }
          }
        }
        .col-4 {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 0 !important;
        }
        .col-3 {
          max-width: 100%;
          flex: 0 0 100%;
          margin: 0 !important;
          margin-bottom: 20px !important;
          label {
            margin-bottom: 0;
          }
        }
      }
    }
    .card-overlay-box:not(:first-of-type) {
      .row {
        align-items: flex-start !important;
      }
      .col-8:nth-child(6),
      .col-9 {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .col-4:nth-child(5) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.step_one-form-plan {
  @media (max-width: 767px) {
    font-size: 14px !important;

    svg {
      font-size: 30px !important;
    }
    span {
      font-size: 16px !important;
    }
  }
}

@keyframes faded {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.toastContainer {
  z-index: 99999;
  overflow: visible !important;

  .toast {
    animation: faded 0.2s ease-in-out;
    box-shadow: 3px 8px 8px rgba($color: #000000, $alpha: 0.1);
    min-width: max-content;

    @media (max-width: 767px) {
      font-size: 11px;
      max-width: 300px;
      padding: 10px;
      align-items: center;
    }
  }
}

.secret_key-remote,
.remote_dash-link {
  @media (max-width: 767px) {
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(100% - 70px);
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }
  }
}

.secret_key-remote {
  span {
    position: relative;
    filter: blur(5px);
    pointer-events: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.no_live_stream {
  width: 60px;
  height: 60px;
  border: 4px solid #d9091a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    left: 50%;
    background: #d9091a;
    transform: rotate(45deg) translateX(-50%);
  }

  img {
    margin-left: -2px;
  }
}

.virtualScenes-row {
  @media (max-width: 767px) {
    margin: 0;
  }
  .col-xl-4 {
    @media (max-width: 1500px) {
      max-width: 50%;
      flex: 0 0 50%;
    }
    @media (max-width: 1100px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
    @media (max-width: 767px) {
      padding: 0;
    }
  }
}

.virtual-single_scene {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 20px;
  position: relative;
  @media (max-width: 1100px) {
    padding: 15px 15px 20px;
    margin-bottom: 20px;
  }
  .scene_media {
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.3);
    height: 200px;
    object-fit: cover;
    width: 100%;
  }
  .video-react {
    border-radius: 8px;
    height: 200px;
    padding-top: unset !important;
    .video-react-big-play-button {
      background: #7cd959;
      color: #000;
      border: none;
      // left: 50%;
      // top: 50%;
      // transform: translateX(-50%) translateY(-50%);
    }
    video {
      border-radius: 8px;
      height: 200px;
      object-fit: cover;
    }
    &.video-react-fullscreen {
      video {
        border-radius: 0px;
        height: 100%;
        object-fit: unset;
      }
    }
  }
  .iconButtonToView {
    position: absolute;
    left: 27px;
    top: 27px;
    background-color: rgba($color: #000000, $alpha: 0.8);
    padding: 7px;
    width: 40px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    outline: none;

    @media (max-width: 767px) {
      left: 22px;
      top: 22px;
    }

    img {
      width: 100%;
    }
  }
  .scene-details {
    padding: 15px 0 0;
    width: 100%;
    @media (max-width: 1100px) {
      span {
        font-size: 10px !important;
      }
    }
    .scene-name {
      font-size: 22px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 1100px) {
        font-size: 18px;
      }
    }
  }
}

.merger_external {
  .merger_header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px 0 20px;

    img {
      height: 60px;
      border-radius: 8px;
    }
  }
  .merger_body {
    display: flex;
    align-items: center;
    margin: 20px 0 25px;

    .alert-thumbnail {
      img {
        max-width: 130px;
        border-radius: 10px;
      }
    }

    .alert-details {
      padding-left: 20px;

      .alertname {
        font-size: 25px;
        font-weight: 700;
        color: #7cd959;
      }
      .alertDesc {
        margin: 5px 0 12px;
        line-height: 1.7;
      }
      .alertOrigin {
        font-size: 16px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.45);
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }
    }
  }
}

.obs_steps {
  &:nth-child(n + 2) {
    border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
    padding-top: 20px;
  }
  .step_head {
    font-weight: 700;
    // color: #7cd959;
    text-transform: uppercase;

    img {
      width: 25px;
      margin-right: 10px;
    }
  }
  .desc {
    padding: 0px 20px 20px 38px;

    @media (max-width: 480px) {
      padding-left: 0;
      padding-right: 0;
    }

    ul {
      margin-top: 14px;
      margin-bottom: 0;
      list-style: inside;

      li {
        padding: 3px 0;

        @media (max-width: 480px) {
          button {
            font-size: 14px !important;
          }
        }

        span {
          color: #7cd959;
          font-weight: 900;
        }
      }
    }
  }
}

.threed_avatars-wrapper {
  .avatars_col-one {
    @media (max-width: 1600px) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }

    @media (max-width: 1280px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.avatars_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.global-announcement {
  margin-bottom: 30px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgba($color: #fff, $alpha: 0.8);
  background: rgba($color: #fff, $alpha: 0.12);
  color: rgba($color: #fff, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    max-width: calc(100% - 50px);
  }

  .close_announcement {
    font-size: 20px;
    cursor: pointer;
    vertical-align: middle;
  }

  &.warning {
    background: rgba($color: #ffad15, $alpha: 0.1);
    border-color: #ffad15;

    strong {
      color: #ffad15;
    }
    .close_announcement {
      color: #ffad15;
    }
  }
  &.success {
    background: rgba($color: #7cd959, $alpha: 0.1);
    border-color: #7cd959;

    strong {
      color: #7cd959;
    }
    .close_announcement {
      color: #7cd959;
    }
  }
  &.error {
    background: rgba($color: #ff3e3e, $alpha: 0.1);
    border-color: #ff3e3e;

    strong {
      color: #ff5050;
    }
    .close_announcement {
      color: #ff5050;
    }
  }
}

.marketplace-new_main {
  @media (max-width: 480px) {
    padding-top: 30px;
  }
  .custom_uploads-wrapper {
    .no-custom-ov-found {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0.85;

      svg {
        margin-bottom: 10px;
        font-size: 100px;
      }
    }
    .elgato_marketplace {
      // display: inline-block;
      background: #171d21;
      border-radius: 8px;
      box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.6);
      img {
        width: 100%;
        max-height: 200px;
        object-fit: cover;
      }
      p {
        display: flex;
        margin-bottom: 0;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: #7cd959;
        font-weight: 700;

        svg {
          font-size: 30px;
        }
      }
    }
    .custom_assets-list {
      display: flex;
      flex-wrap: wrap;

      .mp-overlay_one {
        margin-bottom: 15px;
      }
    }
  }
}

.games_list-wrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  .games_list {
    padding-bottom: 20px;
    @-moz-document url-prefix() {
      overflow-x: hidden;
    }
  }

  .scroll-button {
    position: absolute;
    top: 84px;
    color: "#fff";
    background: rgb(228, 228, 228);
    padding: 0;
    z-index: 10;
    color: #000;
    opacity: 0;
    -webkit-transition: 0.25s all;
    transition: 0.25s all;
    box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.8);

    &:hover {
      background: rgba($color: #fff, $alpha: 0.6);
    }

    &.scroll-left {
      left: 10px;
    }
    &.scroll-right {
      right: 10px;
    }
    @media (max-width: 1440px) {
      top: 70px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 80px;
    left: 0;
    top: 0;
    height: calc(100% - 47px);
    background: linear-gradient(90deg, #000 35%, transparent);
    opacity: 0;
    border-radius: 8px 0px 0px 0;
    z-index: 9;
    -webkit-transition: 0.25s all;
    transition: 0.25s all;
    display: none;
  }
  &::after {
    content: "";
    position: absolute;
    width: 80px;
    right: 0;
    top: 0;
    height: calc(100% - 47px);
    background: linear-gradient(-90deg, #000 35%, transparent);
    opacity: 0;
    border-radius: 0 8px 0px 0;
    z-index: 9;
    -webkit-transition: 0.25s all;
    transition: 0.25s all;
    display: none;
  }

  &:hover {
    .scroll-button {
      opacity: 1;
    }
    &::before,
    &::after {
      opacity: 0.6;
    }
  }
}

.flex-div-scrollable_wrapper {
  &::before,
  &::after {
    top: 10px;
    height: calc(100% - 30px);
  }

  .scroll-button {
    top: 118px;
  }
}

.animfaces_tabs {
  .flex-div-scrollable {
    display: flex;
    align-items: center;
    overflow: visible;
    margin: 0 -10px;

    &.no-scroll {
      flex-wrap: wrap;

      .mp-overlay_one {
        margin-bottom: 15px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .mp-overlay_one {
    width: calc(33.3% - 20px);
    margin: 0 10px;
    margin-top: 10px;

    @media (max-width: 1600px) {
      padding: 12px;

      p {
        font-size: 14px;
      }
      .add_overlay {
        width: 26px;
        height: 26px;
        svg {
          font-size: 18px;
        }
      }
    }

    @media (max-width: 1400px) {
      padding: 16px;
      width: calc(50% - 20px);
    }
    @media (max-width: 991px) {
      width: calc(33.33% - 20px);
      padding: 12px;
    }
    @media (max-width: 480px) {
      width: calc(50% - 20px);
      .add_overlay {
        width: 20px;
        height: 20px;
        top: -6px;
        right: -6px;
        svg {
          font-size: 14px;
        }
      }
    }
  }
  .row {
    margin: 0 -20px;

    .col-lg-6,
    .col-lg-12.singular {
      padding: 0 20px;

      @media (max-width: 991px) {
        border: none !important;
      }
    }

    @media (max-width: 767px) {
      margin: 0 -6px;

      .col-lg-6,
      .col-lg-12.singular {
        padding: 0 6px;
      }
    }

    .col-lg-12.singular {
      border: none !important;
      .mp-overlay_one {
        width: calc(16.66% - 20px);

        @media (max-width: 1400px) {
          padding: 12px;
          width: calc(20% - 20px);
        }
        @media (max-width: 1280px) {
          width: calc(25% - 20px);
        }
        @media (max-width: 991px) {
          width: calc(33.33% - 20px);
        }
        @media (max-width: 480px) {
          width: calc(50% - 20px);
        }
      }
    }
  }
}

.custom_assets-list {
  .flex-div-scrollable {
    display: flex;
    align-items: center;
    overflow: visible;
    margin: 0 -10px;
    flex-wrap: wrap;
    width: 100%;

    & > div {
      width: calc(20% - 20px);
      margin: 0 10px;
      margin-top: 10px;

      @media (max-width: 1600px) {
        .mp-overlay_one {
          padding: 12px;
        }

        p {
          font-size: 14px;
        }
        .add_overlay {
          width: 26px;
          height: 26px;
          svg {
            font-size: 18px;
          }
        }
      }

      @media (max-width: 1400px) {
        .mp-overlay_one {
          padding: 16px;
        }
        width: calc(25% - 20px);
      }
      @media (max-width: 991px) {
        width: calc(33.33% - 20px);
        .mp-overlay_one {
          padding: 12px;
        }

        p {
          margin-top: 9px !important;
        }
      }
      @media (max-width: 480px) {
        width: calc(50% - 20px);

        p {
          margin-top: 9px !important;
        }
        .add_overlay {
          width: 20px;
          height: 20px;
          top: -6px;
          right: -6px;
          svg {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.img-or-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 8px;
  padding: 20px; /* Adjust if needed */
  box-sizing: border-box;
  margin: 0 auto; /* Center the container if it doesn't span full width */
  max-width: 300px; /* Or your desired width */
  height: 300px; /* Or desired height, adjusting for aspect ratio */
  width: calc(
    100% - 40px
  ); /* Adjust based on parent padding to ensure it fits well */
}

.rounded-square-img,
.icon-container {
  width: 100%; /* Fill to the container's width minus padding */
  height: 100%; /* Fill to the container's height minus padding */
  object-fit: contain; /* Ensure the entire image fits within the container */
  border-radius: 8px;
  object-position: center; /* Center the image within the container */
}

.face-anim-icon {
  position: absolute;
  top: 30px;
  left: 0;
  padding: 5px 20px 5px 15px;
  background-color: rgb(124, 217, 89);
  border-radius: 4px 50px 50px 4px;
  height: 32px;
  box-shadow: 0px 0px 4px rgba($color: #000000, $alpha: 0.4);
  z-index: 1;
}

.informative_flex {
  display: flex;
  align-items: center;
  margin: 0 -25px;

  @media (max-width: 1367px) {
    flex-direction: column-reverse;
  }

  .left_discover {
    width: 450px;
    padding: 0 25px;
    border-right: 1px solid rgba($color: #fff, $alpha: 0.1);
    text-align: center;

    img {
      max-width: 80%;
    }

    button {
      max-width: 80%;
      margin: auto;
    }

    @media (max-width: 1600px) {
      width: 300px;
      img {
        max-width: 100%;
      }

      button {
        max-width: 100%;
      }
    }
    @media (max-width: 1367px) {
      border-right: none;
      width: 100%;
      margin-top: 30px;
      text-align: center;

      img {
        max-width: 100%;
        width: auto;
        border-radius: 10px;
      }

      button {
        max-width: 400px;
        margin: auto;
      }
    }
  }
  .right_video {
    padding: 0 25px;
    width: calc(100% - 450px);
    display: flex;
    align-items: center;

    @media (max-width: 1600px) {
      width: calc(100% - 300px);
      padding-left: 0;
    }

    @media (max-width: 1367px) {
      width: 100%;
      flex-direction: column;
      padding-left: 25px;
    }

    .setup_info {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      @media (max-width: 1367px) {
        text-align: center;
      }
      .tagline {
        font-size: 22px;
        margin-bottom: 10px;
        opacity: 0.3;
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          margin-bottom: 10px;
          width: 40px;
          height: 40px;
        }
        @media (max-width: 1700px) {
          font-size: 18px;
        }
        @media (max-width: 1600px) {
          font-size: 16px;

          svg {
            width: 30px;
            height: 30px;
          }
        }
        @media (max-width: 480px) {
          font-size: 14px;
        }
      }
      .setup-text {
        font-size: 55px;
        font-weight: 700;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
        line-height: 1.1;
        color: rgba($color: #fff, $alpha: 0.3);
        text-align: center;
        span {
          color: #7cd959;
        }
        @media (max-width: 1700px) {
          font-size: 45px;
        }
        @media (max-width: 1600px) {
          font-size: 38px;
          margin-left: 40px;
        }
        @media (max-width: 1367px) {
          font-size: 45px;
        }
        @media (max-width: 480px) {
          font-size: 35px;
        }
      }
      .small-text {
        text-transform: uppercase;
        letter-spacing: 6px;
        color: rgba($color: #fff, $alpha: 0.3);
        font-size: 14px;
        text-align: center;

        @media (max-width: 1600px) {
          font-size: 12px;
          margin-left: 40px;
        }
        @media (max-width: 1367px) {
          margin-left: 0;
        }
        @media (max-width: 480px) {
          letter-spacing: 4px;
        }
      }
    }

    .quickstart_video {
      width: 450px;
      margin-left: auto;

      @media (max-width: 1700px) {
        width: 400px;
      }
      @media (max-width: 1600px) {
        width: 350px;
      }
      @media (max-width: 1367px) {
        width: 100%;
        margin-top: 25px;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
        padding-bottom: 30px;
      }
    }
  }
}

.donation_main-wrapper {
  background-color: #171d21;
  position: relative;
  .donation_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.35;
  }
  .container.max-width {
    max-width: 1440px;
  }
  .tips-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -5px;
    min-height: calc(100vh - 84px);
    padding: 80px 0 50px;
    position: relative;
    z-index: 1;

    & > div {
      padding: 0 5px;

      & > :first-child {
        min-height: 80px;
        margin-bottom: 12px !important;
        display: flex;
        align-items: flex-end;

        .section-head {
          font-size: 18px;
          span {
            font-weight: 700;
            color: #7cd959;
          }
        }
      }

      .inner_wrapper {
        background: rgba(40, 54, 66, 0.55);
        backdrop-filter: blur(12px);
        border-radius: 12px;
        padding: 20px;
        box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.3);
        min-height: 500px;

        .face-anim-inner {
          display: flex;
          align-items: flex-end;

          & > div {
            width: 50%;

            .MuiTabs-flexContainer {
              button {
                width: 50%;
                margin: 0 !important;
                color: #fff;
              }
            }
          }
          .right-preview {
            padding-left: 30px;
            .supported_cards {
              text-align: center;
              margin: 10px 0 14px;

              span {
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: 2px;
              }

              .cards {
                margin-left: 3px;
                max-width: 35px;

                &.amex {
                  max-height: 22px;
                  background-color: #fff;
                }
              }
            }
            .preview {
              text-align: center;
              background-color: #171d21;
              border-radius: 10px;
              padding: 15px 10px;
              p {
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 2px;
                color: #7cd959;
              }
              img {
                width: 100%;
                border-radius: 8px;
                margin-bottom: 10px;
              }
              span {
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 2px;
              }
            }
            button {
              width: 100%;
              border-radius: 50px;
              border: 2px solid #7cd959;
              padding: 10px 15px;
              color: #fff;
              background-color: rgba(124, 217, 89, 0.2);
              font-size: 22px;
              text-transform: uppercase;
              cursor: pointer;
              font-weight: 700;

              span {
                color: #7cd959;
              }
              &:hover {
                background-color: rgba(124, 217, 89, 0.3);
              }
            }
          }
          .flex-box {
            display: flex;
            flex-wrap: wrap;
            min-height: 405px;
            max-height: 405px;
            overflow: auto;
            margin-right: -10px;
            padding-right: 10px;

            img {
              width: 85px;
              height: 85px;
              object-fit: cover;
              border-radius: 10px;
              margin-right: 10px;
              margin-bottom: 10px;
              cursor: pointer;

              &:nth-child(4n) {
                margin-right: 0;
              }
            }
          }
        }

        .heading {
          position: relative;
          padding-left: 16px;
          margin-bottom: 0;
          color: #7cd959;

          &::after {
            content: "";
            position: absolute;
            left: 4px;
            top: 50%;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            background-color: #7cd959;
          }
        }
        .tip_money {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > div {
            width: 33.33%;
            margin: 0 5px;
            border: 1px solid #7cd959;
            padding: 7px 12px 6px;
            color: #7cd959;
            background-color: rgba(124, 217, 89, 0.15);
            border-radius: 50px;
            text-align: center;
            font-weight: 500;
            cursor: pointer;

            &.active {
              background-color: rgba(124, 217, 89, 1);
              color: #000;
            }
          }
        }
        .tip_input {
          border: none;
          background-color: #171d21;
          color: #fff;
          width: 100%;
          border-radius: 50px;
          padding: 12px 20px;
        }
        .tip_textarea {
          width: 100%;
          height: 150px;
          background-color: #171d21;
          border: none;
          border-radius: 12px;
          color: #fff;
          padding: 12px 20px;
        }
      }
      .streamer {
        display: flex;
        align-items: center;

        .streamer-photo {
          &.online {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              width: 10px;
              height: 10px;
              background: #7cd959;
              border-radius: 50%;
              transform: translate(-4px, -3px);
              box-shadow: 0px 0px 3px rgba($color: #000000, $alpha: 1);
            }
          }

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .streamer-details {
          width: calc(100% - 60px);
          padding-left: 15px;

          p {
            font-size: 18px;
            font-weight: 700;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            line-height: 1.6;

            img {
              margin-left: 6px;
            }
          }

          span {
            color: #7cd959;
            font-size: 14px;
          }
        }
      }
    }

    .streamers-info {
      width: 400px;
    }
    .face-animations {
      width: calc(100% - 600px);
    }
    .top-donators {
      width: 200px;
      text-align: center;
      font-size: 13px;

      .inner_wrapper > div {
        margin: 10px 0;
      }

      .top {
        font-size: 14px;
        padding-bottom: 10px;
        border-bottom: 1px solid #818181;
        margin-bottom: 10px;
        font-weight: 700;
        margin-top: 0 !important;

        img {
          max-width: 50px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
